import React, { useEffect, useState } from "react";
import { graphql, navigate } from "gatsby";

import { motion } from "framer-motion";

import { DefaultContainer } from "components/common/Structure";
import Footer from "components/footer/Footer";
import Works from "components/works/Works";

const WhatWeveDone = (props) => {
  //PROPS
  const {
    data: {
      page: {
        whatwevedone: { works, title, subtitle },
      },
    },
    setColor,
  } = props;
  //PROPS

  //DEFS
  const lang =
    props.pageContext.translations[0].language.code === "EN" ? "PT" : "EN";
  //DEFS

  //PAGEANIMS
  const pageAnims = {
    hidden: {
      opacity: 0,
      y: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: 0, duration: 1 },
    },
    exit: {
      opacity: 0,
      y: 0,
      transition: { ease: "easeInOut", duration: 1 },
    },
    exit2: {
      opacity: 1,
      transition: { ease: "easeInOut", duration: 0 },
    },
  };
  //PAGEANIMS

  //STATES
  const [toProj, setToProj] = useState(false);
  //STATES

  //FUNCS
  const goTo = ({ link, x, y, height, width }) => {
    setToProj(true);

    navigate(link, {
      state: { x: x, y: y, height: height, width: width },
    });
  };
  //FUNCS

  useEffect(() => {
    setColor(true);
  }, []);

  return (
    <>
      <motion.div
        variants={pageAnims}
        initial="hidden"
        animate="visible"
        exit={toProj ? "exit2" : "exit"}
      >
        <DefaultContainer>
          <Works works={works} title={title} subtitle={subtitle} goTo={goTo} />
        </DefaultContainer>
        <Footer lang={lang} />
      </motion.div>
    </>
  );
};

export const query = graphql`
  query WhatWeveDone($id: String) {
    page: wpPage(id: { eq: $id }) {
      whatwevedone {
        works {
          ... on WpPost {
            uri
            title
            seo {
              slug
            }
            work {
              dynamicContent {
                ... on WpPost_Work_DynamicContent_Image {
                  fieldGroupName
                }
                ... on WpPost_Work_DynamicContent_TextBlock {
                  fieldGroupName
                  textBlock
                }
              }
              color
              text
              services
              client
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FIXED
                      width: 1920
                      quality: 100
                      formats: NO_CHANGE
                    )
                  }
                }
              }
            }
          }
        }
        title
        subtitle
      }
    }
  }
`;

export default WhatWeveDone;
