import React from "react";

import HTMLReactParser from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

import { WorkC } from "./Work.styles";

const Work = (props) => {
  const { data, index, getPos } = props;

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  return (
    <WorkC
      ref={ref}
      className="workThumb"
      id={`work${index}`}
      onClick={() => {
        getPos(`work${index}`, data.seo.slug);
      }}
    >
      <div className="work_image">
        <GatsbyImage
          image={
            inView
              ? data.work.image.localFile.childImageSharp.gatsbyImageData
              : null
          }
        />
      </div>
      <div className="work_info">
        <h3>{data.title && HTMLReactParser(data.title)}</h3>
        <ul>
          {data.work.services.map((service, index) => {
            return (
              <li key={index}>
                <span></span>
                <p>{service}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </WorkC>
  );
};

export default Work;
