import React from "react";

import HTMLReactParser from "html-react-parser";
import gsap from "gsap";

import { WorksContainer } from "./Works.styles";
import Work from "./work";

const Works = (props) => {
  //PROPS
  const { title, subtitle, works, goTo } = props;
  //PROPS

  //DEFS
  const tl = gsap.timeline();
  //DEFS

  //FUNCS
  function getPos(id, uri) {
    // yay readability
    let el = document.getElementById(id);

    for (
      var lx = 0, ly = 0;
      el != null;
      lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent
    );

    var offsets = document.getElementById(id).getBoundingClientRect();
    var top = offsets.top;
    var left = offsets.left;
    var height = offsets.height;
    var width = offsets.width;

    let list = Array.from(document.querySelectorAll(".workThumb"))
      .filter((script) => script.id !== id)
      .map((script) => script);

    tl.to(list, {
      duration: 0.3,
      css: {
        opacity: 0,
      },
    })
      .to(`#${id} .work_info`, {
        duration: 0.3,
        delay: -0.3,
        css: {
          opacity: 0,
        },
      })
      // .to(`body`, {
      //   duration: 0.0,
      //   overflowX: "hidden",
      // })
      .to(`.Tfooter`, {
        duration: 0.2,
        delay: -0.3,
        opacity: 0,
      })
      .to(`#header`, {
        duration: 0.25,
        delay: -0.25,
        css: {
          backgroundColor: "transparent",
          padding: "44px 0 37px",
        },
      })
      .to(`#${id}  .gatsby-image-wrapper img`, {
        duration: 0.0,
        position: "fixed",
        left: left,
        top: top,
        height: 400,
        width: width,
        scale: 1,
      })
      .to(`#${id} .gatsby-image-wrapper img`, {
        duration: 0.6,
        left: 0,
        top: 110,
        height: "400px",
        width: "100vw",
      })
      // .to(`#${id} .gatsby-image-wrapper img`, {
      //   duration: 0.6,
      //   delay: -1.2,
      // })
      .then(() =>
        goTo({
          link: `${uri}`,
        })
      );
  }
  //FUNCS

  return (
    <WorksContainer className="container container--xsmall">
      {title && HTMLReactParser(title)}
      {subtitle && HTMLReactParser(subtitle)}
      <div className="works">
        {works?.map((work, index) => {
          return <Work data={work} key={index} index={index} getPos={getPos} />;
        })}
      </div>
    </WorksContainer>
  );
};

export default Works;
